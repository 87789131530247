import React, { useContext, useState } from "react"
import I18n from "i18n-js"

import { openLogin, openRegistration } from "../gigya"
import { postForm } from "../utils"
import Button from "@root/components/Button"
import SectionTitle from "@root/components/SectionTitle"
import Carousel from "@root/components/Carousel"
import { useIsMobile } from "@root/misc/isMobile"

import { AppLinks } from "@root/types"
import iosStoreImg from "@images/download__ios.png"
import androidStoreImg from "@images/download__android.png"
import EnterCodesForIOSButton from "@root/components/EnterCodesForIOSButton"

export interface CompInit {
  applinks: AppLinks
}

const TEASER_IMAGE_PROJECTS = [
  "fcbq24-25",
  "foot2024",
  "fifa365_2025",
  "brasileirao2024",
  "laliga2024-25",
  // "voleibol2024",
  // "asobal23-24",
  // "fcbq23-24",
  // "calciatori-femminile23-24",
  // "calciatori23-24",
  // "foot2023",
  // "ssl2023",
  // "teamchile2023",
  // "fifa365",
  // "laliga",
] as const

const __teaserImgContext = require.context("@images", true, /teaser--.*(jpg)$/)
const teaserImgSrc = (project: typeof TEASER_IMAGE_PROJECTS[number], locale: string) => {
  return __teaserImgContext(`./teaser--${project}--${locale.toLocaleLowerCase()}.jpg`)
}

export const LandingContext = React.createContext<{ comingSoon?: boolean, mode: "desktop" | "ios" | "android" }>({ mode: "desktop" })

function loginAsGuest() {
  postForm("/session/guest")
}

const b = (key: string) => `web.pages.landing.${key}`

export const LandingActionArea: React.FC<{ appLinks: AppLinks }> = ({ appLinks }) => {
  const { mode, comingSoon } = useContext(LandingContext)

  return <>
    {mode === "ios" &&
      <div className="neo__landing__infoForMobileUsers">
        {comingSoon
          ? <div className="neo__landing__comingSoon__holder"><div className="neo__landing__comingSoon"><span>{I18n.t("coming_soon.title")}</span></div></div>
          : <div className="neo__landing__iosActionsHolder">
            <EnterCodesForIOSButton>{I18n.t("web.pages.landing.ios_action_enter_codes")}</EnterCodesForIOSButton>
            <Button href={appLinks.ios} target="_blank" className="neo__landing__buttonIOSGetGame">
              <span className="neo__landing__buttonIOSGetGame__content">
                <span>{I18n.t("web.pages.landing.ios_action_get_game")}</span>
                <img src={iosStoreImg} />
              </span>
            </Button>
          </div>
        }
      </div>}
    {mode === "android" &&
      <div className="neo__landing__infoForMobileUsers">
        {comingSoon
          ? <div className="neo__landing__comingSoon__holder"><div className="neo__landing__comingSoon"><span>{I18n.t("coming_soon.title")}</span></div></div>
          : <div className="neo__landing__androidActionsHolder">
            <a href={appLinks.ios}><img src={iosStoreImg} /></a>
            <a href={appLinks.android}><img src={androidStoreImg} /></a>
          </div>
        }
      </div>}
    {mode === "desktop" &&
      <>
        <div className="neo__landing__actions">
          {comingSoon && <div className="neo__landing__comingSoon"><span>{I18n.t("coming_soon.title")}</span></div>}
          <Button disabled={comingSoon} onClick={() => openLogin()}>{I18n.t("web.pages.landing.action_login")}</Button>
          <Button disabled={comingSoon} onClick={() => openRegistration()}>{I18n.t("web.pages.landing.action_register")}</Button>
          <Button disabled={comingSoon} onClick={() => loginAsGuest()}>{I18n.t("web.pages.landing.login_as_guest")}</Button>
        </div>
        {!comingSoon && <div className="neo__landing__desktopEnterCodesButtonHolder">
          <EnterCodesForIOSButton>{I18n.t("web.pages.landing.desktop_action_enter_codes")}</EnterCodesForIOSButton>
        </div>}
      </>}
  </>
}

const TeaserSlide: React.FC<{ project: typeof TEASER_IMAGE_PROJECTS[number], comingSoon: boolean }> = ({ project, comingSoon }) => {
  const asteriskExplanation = I18n.t(`web.pages.landing.${project}.teaser_asterisk_explanation`, { defaultValue: "" })
  return (
    <div className="neo__landing__teaser" key={project}>
      <div className="neo__landing__teaser__holder">
        <img className="neo__landing__teaser" src={teaserImgSrc(project, I18n.locale)} />
        {comingSoon && <div className="neo__landing__hiddenStuff" onClick={() => location.href = "/curtain/raise"} />}
      </div>
      <div className="neo__landing__teaserInfos">
        <div className="neo__landing__infoBelowTeaser">{I18n.t(`web.pages.landing.${project}.info_below_teaser`)}</div>
        {(asteriskExplanation && asteriskExplanation.length > 0) &&
          <div className="neo__landing__teaserAsteriskExplanation">{"* "}{asteriskExplanation}</div>
        }
      </div>
    </div>
  )
}

const Landing: React.FC<{ comingSoon?: boolean, init: CompInit }> = ({ comingSoon, init }) => {
  const { isMobile, isIOS, isAndroid } = useIsMobile()

  return <LandingContext.Provider value={{
    comingSoon,
    mode: isMobile
      ? (isIOS ? "ios" : "android")
      : "desktop"}}>
    <div className="neo__landing">
      <div className="neo__landing__inner">
        <SectionTitle>{I18n.t(b("title"))}</SectionTitle>
        <Carousel>
          {TEASER_IMAGE_PROJECTS.map((teaserProject) => {
            return <TeaserSlide key={teaserProject} project={teaserProject} comingSoon={comingSoon} />
          })}
        </Carousel>
        <LandingActionArea appLinks={init.applinks} />
      </div>
    </div>
  </LandingContext.Provider>
}

export default Landing
